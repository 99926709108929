import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Button, Col, Modal, Row, Container, Dropdown, Alert } from 'react-bootstrap';
import { DataManager } from '../../core/data-manager';
import { ApiManager } from '../../core/api-manager';
import { LoginManager } from '../../core/login-manager';
import { LanguageProvider } from '../../core/language-provider';
import ModalList from '../../components/Modals/ModalList';
import ModalEntityViewer from '../../components/Modals/ModalEntityViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import moment from 'moment';



export default function DataGridLastTdHook({ item, props, entity = null, refreshData }) {

    const navigate = useNavigate()


    useEffect(() => {
        console.warn(item)
    }, []);

    //customer
    const [customerHistoryEntity, setCustomerHistoryEntity] = useState(null)
    const [customerHistorySearchObj, setCustomerHistorySearchObj] = useState(null)
    const [statusCustomerModal, setStatusCustomerModal] = useState(false)
    const [statusPaymentModal, setStatusPaymentModal] = useState(false)
    const [paymentEntity, setPaymentyEntity] = useState(null)
    const [paymentSearchObj, setPaymentSearchObj] = useState(null)
    const [statusPaymentOrderModal, setStatusPaymentOrderModal] = useState(false)
    function showCustomerOffer(item) {
        let query = ''
        let fields = []
        let constraints = [{ "fieldName": "idCustomer", type: "value", value: item.id }]

        setCustomerHistorySearchObj({
            query: query,
            fields: fields,
            constraints: constraints
        })

        setCustomerHistoryEntity('customer_offer')
        setStatusCustomerModal(true)
    }
    function showCustomerOrder(item) {
        let query = ''
        let fields = []
        let constraints = [{ "fieldName": "idCustomer", type: "value", value: item.id }]

        setCustomerHistorySearchObj({
            query: query,
            fields: fields,
            constraints: constraints
        })

        setCustomerHistoryEntity('customer_order')
        setStatusCustomerModal(true)
    }
    function closeCustomerModal() {
        setCustomerHistoryEntity(null)
        setStatusCustomerModal(false)
    }



    function showModalPayment(item) {
        let query = ''
        let fields = []
        let constraints = [{ "fieldName": "idCustomer", type: "value", value: item.id }]

        setPaymentSearchObj({
            query: query,
            fields: fields,
            constraints: constraints
        })

        setPaymentyEntity('customer_order_payment')
        setStatusPaymentModal(true)
    }
    function closeModalPayment() {
        setPaymentyEntity(null)
        setStatusPaymentModal(false)
    }

    function newCustomerOffer(item) {
        navigate('/entity/customer_offer/add', {
            state: {
                body: {
                    idCustomer: item
                }
            }
        })
    }
    function newCustomerOrder(item) {
        navigate('/entity/customer_order/add', {
            state: {
                body: {
                    idCustomer: item
                }
            }
        })
    }




    //customer offer
    function previewCustomerOffer(item) {
        navigate('/page/CustomerOfferPreview?id=' + item.id)
    }
    const [statusModalEntityViewerCustomerOffer, setStatusModalEntityViewerCustomerOffer] = useState(false)
    function closeModalEntityViewerCustomerOffer() {
        setStatusModalEntityViewerCustomerOffer(false)
    }
    function openModalEntityViewerCustomerOffer() {
        setStatusModalEntityViewerCustomerOffer(true)
    }

    const [statusModalEntityViewerCustomerOrder, setStatusModalEntityViewerCustomerOrder] = useState(false)
    function closeModalEntityViewerCustomerOrder() {
        setStatusModalEntityViewerCustomerOrder(false)
    }
    function openModalEntityViewerCustomerOrder() {
        setStatusModalEntityViewerCustomerOrder(true)
    }

    const [statusModalEntityViewerSupplierOrder, setStatusModalEntityViewerSupplierOrder] = useState(false)
    function closeModalEntityViewerSupplierOrder() {
        setStatusModalEntityViewerSupplierOrder(false)
    }
    function openModalEntityViewerSupplierOrder() {
        setStatusModalEntityViewerSupplierOrder(true)
    }



    //customer order
    function previewCustomerOrder(item) {
        navigate('/page/CustomerOrderPreview?id=' + item.id)
    }
    function ddtCustomerOrder(item) {
        navigate('/page/CustomerDdtPreview?id=' + item.id)
    }

    function newCustomerOrderPayment(item) {
        navigate('/entity/customer_order_payment/add', {
            state: {
                body: {
                    idCustomerOrder: item
                }
            }
        })
    }



    const [dataPaymentOrderModal, setDataPaymentOrderModal] = useState(false)
    async function showModalPaymentOrder(item) {
        setStatusPaymentOrderModal(true)

        let constraints = [{ "fieldName": "idCustomerOrder", type: "value", value: item.id }]
        var response = await DataManager.searchItems('customer_order_payment', 1, 100, { 'field': 'id', 'order': 'desc' }, '', [], constraints)
        if (response.success === 1) {
            setDataPaymentOrderModal(response.body)
            console.warn(response.body)
        }
    }
    function closeModalPaymentOrder() {
        setStatusPaymentOrderModal(false)
        setDataPaymentOrderModal(null)
    }



    //supplier order
    function previewSupplierOrder(item) {
        navigate('/page/SupplierOrderPreview?id=' + item.id)
    }


    async function customerOfferConvertInOrder() {
        var userInfo = LoginManager.getUserInfo();
        console.warn('item', item)
        const id = toast.loading("Conversione preventivo...")

        if (item.status && item.status.value === "10_CONFIRMED") {
            toast.update(id, { render: "Questo preventivo è già stato convertito in ordine.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
        }
        else {
            var body = {
                status: { value: '10_CONFIRMED' }
            }
            var response = await DataManager.updateItem('customer_offer', body, item.id)
            console.warn(response)
            if (response.success === 1) {
                var sorting = {
                    'field': 'id',
                    'order': 'desc'
                }
                var constraints = [{ "fieldName": "levelUser", type: "value", value: 1 }]
                var resNotifiche = await DataManager.searchItems('gyv_notification', 1, 50, sorting, '', [], constraints);
                console.log(resNotifiche);
                if (resNotifiche.success === 1 && resNotifiche.body.length > 0) {
                    for (let i = 0; i < resNotifiche.body.length; i++) {
                        if (resNotifiche.body[i].notificationAll === 1 && resNotifiche.body[i].notificationOffer === 1) {
                            var bodyEmail = {
                                emailTo: resNotifiche.body[i].emailUser,
                                riferimento: item.internalCode,
                                idOrder: item.id,
                                entity: 'offer',
                                stato: 'CONVERTITO IN ORDINE'
                            }
                            var responseEmail = await ApiManager.sendAuthenticatedRequest('/custom/send_mail_change_status', bodyEmail);
                            console.warn(responseEmail);
                        }
                    }
                }

                var sortingOffer = {
                    'field': 'id',
                    'order': 'desc'
                }
                var constraintsOffer = [{ "fieldName": "id", type: "value", value: item.id }]
                var resultOffer = await DataManager.searchItems('customer_offer', 1, 50, sortingOffer, '', [], constraintsOffer);
                if (resultOffer.success === 1) {

                    console.log(resultOffer);
                    toast.update(id, { render: "Preventivo confermato. Compila l'ordine con i campi mancanti. RICORDATI DI SALVARE L'ORDINE.", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
                    navigate('/entity/customer_order/add', {
                        state: {
                            body: {
                                idCustomerOffer: { id: item.id },
                                idCustomer: { id: resultOffer.body[0].idCustomer.id, name: resultOffer.body[0].idCustomer.name },
                                idSalesAgent: resultOffer.body[0].idSalesAgent,
                                discount: resultOffer.body[0].discount,
                                notes: resultOffer.body[0].notes,
                                rows: resultOffer.body[0].rows,
                                files: resultOffer.body[0].files,
                                riferimento: resultOffer.body[0].riferimento,
                                status: { value: '0_PENDING' },
                                vat: resultOffer.body[0].vat,
                                idPaymentCondition: resultOffer.body[0].idPaymentCondition ? { id: resultOffer.body[0].idPaymentCondition.id, name: resultOffer.body[0].idPaymentCondition.name } : null,
                                parking: resultOffer.body[0].parking,
                                parquet: resultOffer.body[0].parquet,
                                pedestrian_zone: resultOffer.body[0].pedestrian_zone,
                                hoist_elevator: resultOffer.body[0].hoist_elevator,
                                floor: resultOffer.body[0].floor,
                                no_traffic_zone: resultOffer.body[0].no_traffic_zone,
                                building: resultOffer.body[0].building,
                                zone: resultOffer.body[0].zone,
                                ladder_truck: resultOffer.body[0].ladder_truck,
                                elevator: resultOffer.body[0].elevator,
                                increase: resultOffer.body[0].increase,
                                discount: resultOffer.body[0].discount,
                                transportCost: resultOffer.body[0].transportCost,
                                deliveryDate: resultOffer.body[0].deliveryDate,
                                deliveryAddress: resultOffer.body[0].deliveryAddress
                            }
                        }
                    })

                }


                console.log(body);
            }
            else {
                if (response.message && response.message != '') {
                    toast.update(id, { render: response.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
                }
                else {
                    toast.update(id, { render: "Non è stato possibile convertire questo preventivo.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: "colored" });
                }
            }
        }
    }


    async function revert() {
        var sorting = {
            'field': 'id',
            'order': 'desc'
        }
        var constraints = [{ "fieldName": "idCustomerOffer", type: "value", value: item.id }]
        var result = await DataManager.searchItems('customer_order', 1, 50, sorting, '', [], constraints);
        if (result.success === 1) {
            if (result.body.length > 0) {
                toast.error('Impossibile fare il revert in quanto è già presente un ordine creato da questo preventivo', { theme: 'colored' })
            } else {
                var data = {
                    status: { value: '0_PENDING' }
                }
                var update = await DataManager.updateItem('customer_offer', data, item.id);
                if (update.success === 1) {
                    toast.success('Revert eseguito con successo!', { theme: 'colored' })
                    refreshData()
                }
            }
        }

    }

    const [statusModalCheck, setStatusModalCheck] = useState(false)
    function closeModalCheck() {
        setStatusModalCheck(false)
    }
    function openModalCheck() {
        setStatusModalCheck(true)
    }

    const [totConfirm, setTotConfirm] = useState(null)
    const [totRows, setTotRows] = useState(null)
    async function checkPrice() {
        var res = await DataManager.getItem('supplier_order', item.id);
        console.log(res)
        if (res.success === 1) {
            var so = res.body[0]
            var a = so.rows.reduce((a, c) => a + parseFloat(c.listPriceOrder), 0);
            if(so.idSupplier.cash_flow_discount !== "0"){
                console.log(a, so.idSupplier.cash_flow_discount)
                a = parseFloat(a) - (parseFloat(a) * parseInt(so.idSupplier.cash_flow_discount)/100)
                console.log(a)
            }
            if(so.idSupplier.commercial_discount !== "0"){
                console.log(a, so.idSupplier.commercial_discount)
                a = parseFloat(a) - (parseFloat(a) * parseInt(so.idSupplier.commercial_discount)/100)
            }


            if (so.files !== null) {
                var b = so.files.reduce((acc, cur) => acc + parseFloat(cur.cost), 0);
            } else {
                var b = parseFloat("0,00")
            }
            setTotConfirm(b)
            setTotRows(a)
            openModalCheck()
        }
    }

    function goToPreview() {
        navigate('/page/PaymentsPreview?id=' + item.id)
    }




    if (entity !== null) {
        return (
            <>
                {(() => {
                    switch (entity) {
                        case 'sample':
                            return (
                                <span>Test</span>
                            );
                        case 'customer':
                            return (
                                <>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="outline-secondary" size='sm'>
                                            Azioni
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => showCustomerOffer(item)}>Preventivi</Dropdown.Item>
                                            <Dropdown.Item onClick={() => newCustomerOffer(item)}>Crea preventivo</Dropdown.Item>
                                            <hr />
                                            <Dropdown.Item onClick={() => showCustomerOrder(item)}>Ordini</Dropdown.Item>
                                            <Dropdown.Item onClick={() => newCustomerOrder(item)}>Crea ordine</Dropdown.Item>
                                            <hr />
                                            <Dropdown.Item onClick={() => showModalPayment(item)}>Storico pagamenti</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>


                                    {statusCustomerModal && customerHistoryEntity ? <ModalList entity={customerHistoryEntity} searchObj={customerHistorySearchObj} status={statusCustomerModal} closeModal={closeCustomerModal} /> : <></>}
                                    {statusPaymentModal && paymentEntity ? <ModalList entity={paymentEntity} searchObj={paymentSearchObj} status={statusPaymentModal} closeModal={closeModalPayment} /> : <></>}

                                </>
                            );
                        case 'gyv_customer_offer':
                            return (
                                <>
                                    {item.status.value === '0_PENDING' || item.status.value === '5_PRESALE' ?
                                        <Button className='m-1' variant='outline-success' size='sm' onClick={() => customerOfferConvertInOrder()}>Converti</Button>
                                        : <></>}

                                    {item.status.value !== '0_PENDING' ? <>
                                        <Button className='m-1' variant='outline-danger' size='sm' onClick={() => revert()}>Annulla</Button>
                                    </> : null}
                                    <Button className='m-1' variant='outline-primary' size='sm' onClick={() => openModalEntityViewerCustomerOffer()}>Dettagli</Button>
                                    <Button onClick={() => previewCustomerOffer(item)} size="sm" variant="outline-secondary" className='m-1'><FontAwesomeIcon className='ms-1 me-1' icon='print'></FontAwesomeIcon></Button>

                                    {statusModalEntityViewerCustomerOffer !== false && (
                                        <ModalEntityViewer entity={'customer_offer'} item={item} status={statusModalEntityViewerCustomerOffer} closeModal={closeModalEntityViewerCustomerOffer} />
                                    )}
                                </>
                            );
                        case 'gyv_customer_order':
                            return (
                                <>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="outline-secondary" size='sm'>
                                            Azioni
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => ddtCustomerOrder(item)}>DDT</Dropdown.Item>
                                            <Dropdown.Item onClick={() => openModalEntityViewerCustomerOrder()}>Dettagli</Dropdown.Item>
                                            <hr />
                                            <Dropdown.Item onClick={() => showModalPaymentOrder(item)}>Storico pagamenti</Dropdown.Item>
                                            <Dropdown.Item onClick={() => newCustomerOrderPayment(item)}>Registra pagamento</Dropdown.Item>
                                            <hr />
                                            <Dropdown.Item onClick={() => previewCustomerOrder(item)}>Stampa</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>


                                    {statusModalEntityViewerCustomerOrder !== false && (
                                        <ModalEntityViewer entity={'customer_order'} item={item} status={statusModalEntityViewerCustomerOrder} closeModal={closeModalEntityViewerCustomerOrder} />
                                    )}
                                    {statusPaymentOrderModal ? (
                                        <>
                                            <Modal show={statusPaymentOrderModal} onHide={closeModalPaymentOrder} size='lg' scrollable>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Storico pagamenti</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {dataPaymentOrderModal && dataPaymentOrderModal.length > 0 ? (
                                                        <Table className='table small'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Data pagamento</th>
                                                                    <th>Importo pagato</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dataPaymentOrderModal.map((payment, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{moment(payment.paymentDate).format('DD/MM/YYYY')}</td>
                                                                            <td>{payment.totalPayment} €</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    ) : (
                                                        <Alert>Nessun pagamento presente.</Alert>
                                                    )}

                                                    <p className='text-end'>Totale ordine: <b>{item.totalPrice} €</b></p>
                                                    <p className='text-end'>Totale pagato: <b>{dataPaymentOrderModal && dataPaymentOrderModal.length > 0 ? dataPaymentOrderModal.reduce((acc, el) => acc + parseFloat(el.totalPayment), 0) : 0} €</b></p>
                                                    <p className='text-end'>Rimanente: <b>{dataPaymentOrderModal && dataPaymentOrderModal.length > 0 ? item.totalPrice - dataPaymentOrderModal.reduce((acc, el) => acc + parseFloat(el.totalPayment), 0) : item.totalPrice} €</b></p>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    {dataPaymentOrderModal && dataPaymentOrderModal.length > 0 ? <Button variant="outline-secondary" onClick={() => goToPreview()}>
                                                        Anteprima di stampa
                                                    </Button> : null}

                                                    <Button variant="secondary" onClick={closeModalPaymentOrder}>
                                                        Chiudi
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>
                                    ) : <></>}

                                </>
                            );
                        case 'gyv_supplier_order':
                            return (
                                <>
                                    <Button className='m-1' variant='outline-secondary' size='sm' onClick={() => checkPrice()}>Confronta</Button>
                                    <Button className='m-1' variant='outline-primary' size='sm' onClick={() => openModalEntityViewerSupplierOrder()}>Dettagli</Button>
                                    <Button onClick={() => previewSupplierOrder(item)} size="sm" variant="outline-secondary" className='m-1'><FontAwesomeIcon className='ms-1 me-1' icon='print'></FontAwesomeIcon></Button>
                                    {statusModalEntityViewerSupplierOrder !== false && (
                                        <ModalEntityViewer entity={'supplier_order'} item={item} status={statusModalEntityViewerSupplierOrder} closeModal={closeModalEntityViewerSupplierOrder} />
                                    )}


                                    <Modal show={statusModalCheck} onHide={closeModalCheck}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confronta</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Row>
                                                <Col className='text-center'>
                                                    <p className='mb-0'>Totale costo righe ordine cliente</p>
                                                    <h3>{parseFloat(totRows).toFixed(2)} €</h3>
                                                </Col>
                                                <Col className='text-center'>
                                                    <p className='mb-0'>Totale conferme d'ordine</p>
                                                    <h3>{parseFloat(totConfirm).toFixed(2)} €</h3>
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col className='text-center'>
                                                    <h6>Differenza</h6>
                                                    <h2 className='text-success'>{totRows - totConfirm} €</h2>
                                                </Col>
                                            </Row>
                                        </Modal.Body>
                                    </Modal>
                                </>
                            );
                        default:
                            return (
                                <></>
                            );
                    }
                })()}
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
};